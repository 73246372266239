import * as React from "react"
import Home from "./home"

// markup
const IndexPage = () => {
  return (
    <Home></Home>
  )
}

export default IndexPage
